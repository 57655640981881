@import 'settings';

h1,
h2,
h3,
h4 {
	font-weight: 500;
}

strong {
	font-weight: bold;
}
