
@font-face {
	font-family: 'Avenir Next';
	src: url('/assets/fonts/AvenirNext-Regular.eot');
	src: url('/assets/fonts/AvenirNext-Regular.eot?#iefix') format('embedded-opentype'),
		url('/assets/fonts/AvenirNext-Regular.woff') format('woff'),
		url('/assets/fonts/AvenirNext-Regular.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'Avenir Next';
	src: url('/assets/fonts/AvenirNext-Bold.eot');
	src: url('/assets/fonts/AvenirNext-Bold.eot?#iefix') format('embedded-opentype'),
		url('/assets/fonts/AvenirNext-Bold.woff') format('woff'),
		url('/assets/fonts/AvenirNext-Bold.ttf') format('truetype');
	font-weight: bold;
	font-style: normal;
}

@font-face {
	font-family: 'Avenir Next';
	src: url('/assets/fonts/AvenirNext-Medium.eot');
	src: url('/assets/fonts/AvenirNext-Medium.eot?#iefix') format('embedded-opentype'),
		url('/assets/fonts/AvenirNext-Medium.woff') format('woff'),
		url('/assets/fonts/AvenirNext-Medium.ttf') format('truetype');
	font-weight: 500;
	font-style: normal;
}

$gray-dark:    #4A4A4A; //lighten($gray-base, 13.5%) // #222
$blue:         	#3a7082; //darken(#428bca, 6.5%) // #337ab7
$brand-primary: $blue;

$font-family-sans-serif: 'Avenir Next', -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif !default;

@import 'bootstrap';